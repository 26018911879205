import React, { Component, createContext } from "react";
import { auth, db } from "../firebase.js";

export const AppContext = createContext();

class AppProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            places: null,
            activePlace: null,
            inviteKey: null,
            activeInvite: null
        };
    }

    componentDidMount = () => {
        auth.onAuthStateChanged(userAuth => {
            this.setState({user: userAuth});
        });

    }

    componentDidUpdate = () => {
        // Do something...
    }

    render() {
        return(
            <AppContext.Provider
                value={{
                    user: this.state.user,
                    inviteKey: this.state.inviteKey,
                    activeInvite: this.state.activeInvite,
                    places: this.state.places,
                    activePlace: this.state.activePlace,
                    setActivePlace: selectedPlace => {
                        console.log("Setting Active Place " + selectedPlace);
                        this.setState({activePlace: selectedPlace}, ()=>{
                            console.log("Active Place Updated!");
                        });
                    },
                    setPlacesList: theList => {
                        console.log("Setting Places List Context");
                        console.log(theList);
                        this.setState({places: theList}, ()=>{
                            console.log("Places List Updated");
                        });
                    },
                    setInviteKey: newKey => {
                        console.log("Setting invite key");
                        this.setState({inviteKey: newKey});
                    },
                    setActiveInvite: theInvite => {
                        this.setState({activeInvite: theInvite}, ()=>{
                            console.log("Active Invite Set");
                            console.log(this.state.activeInvite);
                        });
                    }
                }}>
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

export default AppProvider;