import React, { Component } from "react";
import { AppContext } from "../providers/AppProvider";
import { Redirect } from "react-router-dom";

class Invite extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editMode:false
        };
    }

    componentDidMount(){
        console.log("Rendering Invite");
        console.log("UID = " + this.context.user.uid);
        console.log("KEY = " + this.props.id);
    }

    getGameStr(){
        var gamesArr = [];
        if(this.props.eightBall){
            gamesArr.push("8 Ball");
        }
        if(this.props.nineBall){
            gamesArr.push("9 Ball");
        }
        if(this.props.tenBall){
            gamesArr.push("10 Ball");
        }
        if(this.props.straightPool){
            gamesArr.push("Straight Pool");
        }
        if(this.props.onePocket){
            gamesArr.push("One Pocket");
        }

        var gamesStr = gamesArr.join(', ');
        return gamesStr;
    }

    getPlayerSkill(){
        var skillStr = this.props.skill + " Player";
        if(this.props.fargo === 'yes'){
            skillStr += " (" + this.props.fargoRating + ")";
        }
        return skillStr;
    }

    getTableStakes(){
        var stakesStr = "";

        if(this.props.stakes === 'stakes1'){
            stakesStr = 'Friendly Game (Host will cover table fees)';
        } else if(this.props.stakes === 'stakes2') {
            stakesStr = 'Friendly Game (Players split table fees)';
        } else if(this.props.stakes === 'stakes3') {
            stakesStr = 'Loser will cover table fees';
        } else if(this.props.stakes === 'stakes4') {
            stakesStr = 'Money Game';
        }

        return stakesStr;
    }

    handleEdit(){
        this.setState({editMode:true});
    }

    render(){

        let p = this.props.p;

        if(this.state.editMode){
            return(
                <Redirect to='/create' />
            );
        } else if(this.context.user.uid === this.props.id){
            return(
                <div className="invite">
                    <div className="inviteTitle">Your Invite (Table {this.props.table})</div>
                    <div className="inviteGames">{this.getGameStr()}</div>
                    <div className="inviteSkill">{this.getPlayerSkill()}</div>
                    <div className="inviteStakes">{this.getTableStakes()}</div>
                    <div className="inviteActions">
                        <div className="small-btn" onClick={()=>this.handleEdit()}>Edit</div>
                        <div className="small-btn" onClick={()=>p.removeInvite()}>Remove</div>
                        <div className="clearer"></div>
                    </div>
                    
                    
                </div>
            );
        } else {
            return(
                <div className="invite">
                    <div className="inviteTitle">Table {this.props.table}</div>
                </div>
            );
        }

        
    }
}

Invite.contextType = AppContext;
export default Invite;