import React, { Component } from "react";
import { signInWithGoogle } from "../firebase.js";

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }

  render(){
    return(
      <div id="sign-in-page" className="page">
        <div className="basic-btn" onClick={()=>signInWithGoogle()}>Sign In</div>
      </div>
    );
  }
}

export default Auth;