import React, { Component } from 'react';
import { render } from 'react-dom';
import PageRouter from "./PageRouter";
import AppProvider, {AppContext} from "./providers/AppProvider";

import './css/styles.css';
import reportWebVitals from './reportWebVitals';

class App extends Component {
  constructor() {
    super();
    this.state = {

    };
  }

  render() {
    console.log("APP STARTED!");
    return (
      <AppProvider>
        <div className="app-wrapper">
          <PageRouter />
        </div>
      </AppProvider>
    )
  }
}

render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
