import React, { Component } from "react";
import Script from 'react-load-script';
import Place from '../components/Place';
import { Link } from "react-router-dom";

import { AppContext } from "../providers/AppProvider";

import BasicButton from '../components/BasicButton';

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lat:'',
            long:'',
            city:'',
            query:'',
            isLoaded:false,
            places: null
        };
    }

    componentDidMount() {
        console.log(this.context.user.uid);

        // should check to see if the user has an active invite
    }

    getPosition = () => {
        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(
                this.handlePosition,
                function(error) {
                    console.error("Error Code = " + error.code + " - " + error.message);
                }
            );
        }
    }

    handlePosition = (pos) =>{
        console.log("YOUR POSITION IS:");
        console.log(pos);
        this.setState({
            lat: pos.coords.latitude,
            long: pos.coords.longitude
        });
        this.handleSearch();
    }

    handleScriptLoad = () => { 
        console.log("GOOGLE MAPS SCRIPT LOADED...");
        if(!this.context.places){
            console.log("Getting Position");
            this.getPosition();
        } else {
            console.log("Already have your location");
        }    
    }

    handleSearch = () => {
        console.log("SEARCHING FOR ROOMS NEAR " + this.state.lat + ", " + this.state.long);
        this.places = new window.google.maps.places.PlacesService(document.getElementById('placeslist'));

        let loc = new window.google.maps.LatLng(this.state.lat, this.state.long);
        
        var req = {
            location:loc,
            radius:'16000',
            keyword:'pool+hall'
        }
        
        this.places.nearbySearch(req, this.handleSearchResponse);
    }

    handleSearchResponse = (results, status) => {
        if(status == window.google.maps.places.PlacesServiceStatus.OK){
            console.log(results);
            //this.setState({places: results});
            this.context.setPlacesList(results);
        }
    }

    getPlaces() {
        console.log("Fetching Places List");
        let places = this.context.places;
        let result = [];

        places.forEach((place, i, arr) => {
            if(place.business_status == "OPERATIONAL") {
                result.push(
                    <div><Place
                        key={place.place_id}
                        pdata={place}
                        pindex={i} /></div>
                );  
            }  
        });
        console.log(result);
        return result;
    }

    handlePlaceSelect = () => {

        // Extract City From Address Object
        const addressObject = this.autocomplete.getPlace();
        const address = addressObject.address_components;
    
        // Check if address is valid
        if (address) {
            console.log(address);
          // Set State
          this.setState(
            {
              city: address[0].long_name,
              query: addressObject.formatted_address,
            }
          );
        }
    }

    render(){
        console.log("Rendering Search Page");

        if(!this.context.places){
            console.log("SEARCHING...");
            return (
                <div className="page">
                    <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvn6Qz4MsQbTnaBptkHbEytPSmH7AafZE&libraries=places"          
                        onLoad={this.handleScriptLoad}        
                    /> 
    
                    <h1>Find Your Pool Room</h1>
                    <p>Searching for nearby rooms...</p>
                    <div id="placeslist"></div>
                    
                </div>
            );
        } else {
            console.log("HAVE RESULTS");
            let thePlaces = this.getPlaces();

            return (
                <div className="page">
                    <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvn6Qz4MsQbTnaBptkHbEytPSmH7AafZE&libraries=places"          
                        onLoad={this.handleScriptLoad}        
                    /> 
    
                    <h1>Find Your Pool Room</h1>
                    <p>Pool rooms near you...</p>
                    <div id="placeslist">{thePlaces}</div>
                    
                    {/* <div><Link to='/zip'>Search by Zip</Link></div> */}
                    
                    <BasicButton label="Sign Out" to='/signout' />
                </div>
            );
        }

        
    }

    
}

Search.contextType = AppContext;
export default Search;

// i'm losing the context when i navigate to different pages. maybe i do have to pass it through props?
// should i use link instead of redirect?