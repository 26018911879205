import firebase from "firebase/app";
import 'firebase/storage';
import 'firebase/database';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyCdE5MekXgoXQXML_KaHqElU5zNfGcwhRg",
    authDomain: "breaker-breaker.firebaseapp.com",
    projectId: "breaker-breaker",
    storageBucket: "breaker-breaker.appspot.com",
    messagingSenderId: "489597222328",
    appId: "1:489597222328:web:fd742aada85c223bc6634d",
    measurementId: "G-MQBLBL9FVC"
};

const provider = new firebase.auth.GoogleAuthProvider();

export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();
//export default firebase.initializeApp(config);
export const db = firebase.database();
export const auth = firebase.auth();

export const signInWithGoogle = () => {
    console.log("Trying to sign in...");
    auth.signInWithPopup(provider).then(result => {
  
    }).catch(error => {
      console.log("Failed");
      console.log(error.message);
    });
    
};


// should prevent a user from posting more than once per room id
// means i'll need user ids