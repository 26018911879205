import React, { Component } from "react";
import { AppContext } from "../providers/AppProvider";
import { Redirect } from "react-router-dom";

class Place extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clicked: false
        };
    }

    handleClick = () => {
        console.log("Place Clicked");
        
        this.context.setActivePlace(this.props.pindex);
        this.setState({clicked:true});
    }

    render(){
        const pname = this.props.pdata.name;
        const pvicinity = this.props.pdata.vicinity;

        if(!this.state.clicked) {
            return(
                <div className="placeCont" onClick={this.handleClick}>
                    <h1>{pname}</h1>
                    <div>{pvicinity}</div>
                </div>
            );
        } else {
            return(
                <Redirect to="/room" />
            );
            
        }

        
    }
}

Place.contextType = AppContext;
export default Place;