import React, { Component } from "react";
import Script from 'react-load-script';
import Place from '../components/Place';

import { AppContext } from "../providers/AppProvider";

import BasicButton from '../components/BasicButton';

class SearchByZip extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            lat:'',
            long:'',
            city:'',
            zip:'',
            query:'',
            isLoaded:false,
            places: null
        };
    }

    handleScriptLoad = () => { 
        console.log("GOOGLE MAPS SCRIPT LOADED...");
        this.setState({isLoaded: true});    
    }

    getSearchBox = () => {
        console.log("GETTING SEARCH BOX");
        if(this.state.isLoaded){
            return(
                <input type="number" 
                    placeholder="Zip Code" 
                    value={this.state.zip} 
                    onChange={e=>{
                        this.setState({zip: e.target.value});
                    }} />
            );
        } else {
            return null;
        }
    }

    handleSearch = () => {
        console.log("SEARCHING FOR ROOMS NEAR " + this.state.zip);
        this.places = new window.google.maps.places.PlacesService(document.getElementById('placeslist'));

        var qStr = 'pool halls in ' + this.state.zip;
        
        var req = {
            query: qStr
        }
        
        this.places.textSearch(req, this.handleSearchResponse);
    }

    handleSearchResponse = (results, status) => {
        if(status == window.google.maps.places.PlacesServiceStatus.OK){
            console.log(results);
            this.setState({places: results});
            this.context.setPlacesList(results);
        }
    }

    getPlaces() {
        console.log("Fetching Places List");
        let places = this.context.places;
        let result = [];

        places.forEach((place, i, arr) => {
            if(place.business_status == "OPERATIONAL") {
                result.push(
                    <div><Place
                        key={place.place_id}
                        pdata={place}
                        pindex={i} /></div>
                );  
            }  
        });
        console.log(result);
        return result;
    }

    render(){
        let box = this.getSearchBox();

        if(!this.context.places){
            return (
                <div className="page">
                    <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvn6Qz4MsQbTnaBptkHbEytPSmH7AafZE&libraries=places"          
                        onLoad={this.handleScriptLoad}        
                    /> 
    
                    <h1>Find Your Pool Room</h1>
                    <p>Enter your zip code...</p>
                    <div>{box}</div>
                    <div className="basic-btn" onClick={()=>this.handleSearch()}>Search</div>
                    <div id="placeslist"></div>
                    
                </div>
            );
        } else {
            let thePlaces = this.getPlaces();
            return (
                <div className="page">
                    <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyBvn6Qz4MsQbTnaBptkHbEytPSmH7AafZE&libraries=places"          
                        onLoad={this.handleScriptLoad}        
                    /> 
    
                    <h1>Find Your Pool Room</h1>
                    <p>Pool rooms near {this.state.zip}</p>
                    <div id="placeslist">{thePlaces}</div>
                    
                </div>
            );
        }

        
    }

}

SearchByZip.contextType = AppContext;
export default SearchByZip;