import React, { Component } from "react";
import { AppContext } from "../providers/AppProvider";
import { Link } from "react-router-dom";
import { db } from "../firebase.js";

import Invite from '../components/Invite';
import BasicButton from '../components/BasicButton';

class Room extends Component {
    constructor(props){
        super(props);
        this.state = {
            invites: null,
            disableNew: false
        };
    }

    componentDidMount(){
        let pid = this.context.places[this.context.activePlace].place_id;
        console.log("Fetching invites for room " + pid);
        db.ref('/rooms/' + pid).once("value", snap=> {
            let inviteList = [];
            if(snap.exists()){
                snap.forEach((invite) => {
                    if(invite.key === this.context.user.uid){
                        this.setState({disableNew: true});
                    }
                    inviteList.push({
                        key: invite.key,
                        eightBall: invite.val().eightBall,
                        nineBall: invite.val().nineBall,
                        tenBall: invite.val().tenBall,
                        straightPool: invite.val().straightPool,
                        onePocket: invite.val().onePocket,
                        skill: invite.val().skill,
                        fargo: invite.val().fargo,
                        fargoRating: invite.val().fargoRating,
                        stakes: invite.val().stakes,
                        table: invite.val().table
                    });
                });
                this.setState({invites: inviteList});
                this.context.setActiveInvite(inviteList);
            } else {
                console.log("No invites found for room");
                if(this.context.activeInvite){
                    this.context.setActiveInvite(null);
                }
            }
        });
    }

    getInvites(){
        let invites = this.state.invites;
        let result = [];

        if(invites.length > 0){
            invites.forEach((invite) => {
                result.push(
                    <Invite
                        p={this}
                        id={invite.key}
                        eightBall={invite.eightBall}
                        nineBall={invite.nineBall}
                        tenBall={invite.tenBall}
                        straightPool={invite.straightPool}
                        onePocket={invite.onePocket}
                        skill={invite.skill}
                        fargo={invite.fargo}
                        fargoRating={invite.fargoRating}
                        stakes={invite.stakes}
                        table={invite.table} />
                );
            });
        } else {
            result.push(<div>No invites found...</div>);
        }

        return result;
    }

    removeInvite = () => {
        let uid = this.context.user.uid;
        let pid = this.context.places[this.context.activePlace].place_id;
        db.ref('/rooms/' + pid + '/' + uid).remove(error => {
            if(error){
                console.log("SOMETHING BROKE");
            } else {
                console.log("DELETED");
                this.context.setActiveInvite(null);
                this.setState({invites: null});
            }
        });
    }

    render(){
        let placeName = this.context.places[this.context.activePlace].name;

        if(this.state.invites){

            let theInvites = this.getInvites();

            if(this.state.disableNew){
                return(
                    <div className="page">
                        <h1>{placeName}</h1>
                        <div>{theInvites}</div>
                        <BasicButton label="Back" to='/' />
                    </div>
                );
            } else {
                return(
                    <div className="page">
                        <h1>{placeName}</h1>
                        <div>{theInvites}</div>
                        <BasicButton label="New Match Invite" to="/create" />
                        <div><Link to="/">Back</Link></div>
                    </div>
                );
            }
        } else {
            return(
                <div className="page">
                    <h1>{placeName}</h1>
                    <div>No invites for room...</div>
                    <BasicButton label="New Match Invite" to="/create" />
                    <div><Link to="/">Back</Link></div>
                </div>
            );
        }
        
    }
}

Room.contextType = AppContext;
export default Room;