import React, { Component } from "react";
import { AppContext } from "../providers/AppProvider";
import { db } from "../firebase.js";
import { Redirect } from "react-router-dom";

class Create extends Component {
    constructor(props){
        super(props);
        this.state = {
            step: 0,
            eightBall: true,
            nineBall: false,
            tenBall: false,
            straightPool: false,
            onePocket: false,
            skill: 'beginner',
            fargo: 'no',
            fargoRating: '',
            stakes: 'stakes1',
            table: null,
            formDone: false
        };
    }

    componentDidMount(){
        if(this.context.activeInvite){
            console.log(this.context.activeInvite);
            this.setState({
                eightBall: this.context.activeInvite[0].eightBall,
                nineBall: this.context.activeInvite[0].nineBall,
                tenBall: this.context.activeInvite[0].tenBall,
                straightPool: this.context.activeInvite[0].straightPool,
                onePocket: this.context.activeInvite[0].onePocket,
                skill: this.context.activeInvite[0].skill,
                fargo: this.context.activeInvite[0].fargo,
                fargoRating: this.context.activeInvite[0].fargoRating,
                stakes: this.context.activeInvite[0].stakes,
                table: this.context.activeInvite[0].table
            });
        }
    }

    nextStep = (e) => {
        var nextStep = this.state.step + 1;
        this.setState({step: nextStep});
    }

    prevStep = (e) => {
        if(this.state.step !== 0){
            var prevStep = this.state.step - 1;
            this.setState({step: prevStep});
        }
    }

    gotoStep(step) {
        this.setState({step: step});
    }

    onRadioChange = (e) => {
        //console.log(e.currentTarget.value);
        if(this.state.step === 1){
            this.setState({skill: e.currentTarget.value});
        } else if(this.state.step === 2) {
            this.setState({fargo: e.currentTarget.value});
        } else if(this.state.step === 3) {
            this.setState({stakes: e.currentTarget.value});
        }
    }

    handleCheck = (e) => {
        console.log("Checkbox Changed");
        console.log(e.currentTarget.id);
        console.log(e.currentTarget.checked);

        if(e.currentTarget.id === 'game1'){
            this.setState({eightBall: !this.state.eightBall});
        } else if(e.currentTarget.id === 'game2'){
            this.setState({nineBall: !this.state.nineBall});
        } else if(e.currentTarget.id === 'game3'){
            this.setState({tenBall: !this.state.tenBall});
        } else if(e.currentTarget.id === 'game4'){
            this.setState({straightPool: !this.state.straightPool});
        } else if(e.currentTarget.id === 'game5'){
            this.setState({onePocket: !this.state.onePocket});
        }
    }

    getFargoRateInput() {
        if(this.state.fargo === 'no'){
            return null;
        } else {
            return(
                <div className="choiceText">
                    <input type="text" 
                        placeholder="Fargo Rating" 
                        value={this.state.fargoRating} 
                        onChange={e=>{
                            this.setState({fargoRating: e.target.value});
                        }} />
                </div>
            );
        }
    }

    getStakes() {
        let { stakes } = this.state;
        if(stakes === 'stakes1'){
            return 'Friendly (you cover table)';
        } else if(stakes === 'stakes2') {
            return 'Friendly (split table)';
        } else if(stakes === 'stakes3') {
            return 'Loser covers table';
        } else {
            return 'Money game';
        }
    }

    getGames() {
        var gamesArr = [];
        if(this.state.eightBall){
            gamesArr.push("8 Ball");
        }
        if(this.state.nineBall){
            gamesArr.push("9 Ball");
        }
        if(this.state.tenBall){
            gamesArr.push("10 Ball");
        }
        if(this.state.straightPool){
            gamesArr.push("Straight Pool");
        }
        if(this.state.onePocket){
            gamesArr.push("One Pocket");
        }

        var gamesStr = gamesArr.join(', ');
        return gamesStr;
    }

    saveInvite() {
        // build the invite object
        var place_id = this.context.places[this.context.activePlace].place_id;
        var uid = this.context.user.uid;
        //var newInviteKey = db.ref().child('rooms').push().key;
        let updates = {};
        let newInvite = {
            eightBall: this.state.eightBall,
            nineBall: this.state.nineBall,
            tenBall: this.state.tenBall,
            straightPool: this.state.straightPool,
            onePocket: this.state.onePocket,
            skill: this.state.skill,
            fargo: this.state.fargo,
            fargoRating: this.state.fargoRating,
            stakes: this.state.stakes,
            table: this.state.table
        }

        updates['/rooms/' + place_id + '/' + uid] = newInvite;
        db.ref().update(updates, error => {
            if(error){
                console.log("SOMETHING BROKE!");
                console.log(error.message);
            } else {
                console.log("Database updated successfully");
                // add key to app provider
                //this.context.setInviteKey(newInviteKey);
                this.setState({formDone: true});
                var cont = [];
                cont.push(newInvite);
                this.context.setActiveInvite(cont);
            }
        });
    }

    render(){

        const pid = this.context.places[this.context.activePlace].place_id;

        if(this.state.formDone === true){
            return(
                <Redirect to="/room" />
            );
        } else if(this.context.inviteKey) {
            return(
                <div className="page">
                    <h1>You already have an invite.</h1>
                </div>
            );
        } else if(this.state.step === 0) {
            return(
                <div className="page">
                    <h1>What's Your Game?</h1>
                    <p>You can pick more than one.</p>
                    <div className="choices">
                        <div className="choice">
                            <div className="choiceLabel">8 Ball</div>
                            <div className="choiceCheck"><input id="game1" type="checkbox" checked={this.state.eightBall} onChange={this.handleCheck}/></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">9 Ball</div>
                            <div className="choiceCheck"><input id="game2" type="checkbox" checked={this.state.nineBall} onChange={this.handleCheck} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">10 Ball</div>
                            <div className="choiceCheck"><input id="game3" type="checkbox" checked={this.state.tenBall} onChange={this.handleCheck} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Straight Pool</div>
                            <div className="choiceCheck"><input id="game4" type="checkbox" checked={this.state.straightPool} onChange={this.handleCheck} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">One Pocket</div>
                            <div className="choiceCheck"><input id="game5" type="checkbox" checked={this.state.onePocket} onChange={this.handleCheck} /></div>
                            <div className="clearer"></div>
                        </div>
    
                    </div>
                    <div className="basic-btn" onClick={(e) => this.nextStep(e)}>Next</div>
                    <div className="footer">{pid}</div>
                </div>
            );
        } else if(this.state.step === 1){
            return(
                <div className="page">
                    <h1>What's Your Skill Level?</h1>
                    <div className="choices">
                        <div className="choice">
                            <div className="choiceLabel">Beginner</div>
                            <div className="choiceCheck"><input name="skill" type="radio" value="Beginner" onChange={this.onRadioChange} checked={this.state.skill === 'Beginner'} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Intermediate</div>
                            <div className="choiceCheck"><input name="skill" type="radio" value="Intermediate" onChange={this.onRadioChange} checked={this.state.skill === 'Intermediate'} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Advanced</div>
                            <div className="choiceCheck"><input name="skill" type="radio" value="Advanced" onChange={this.onRadioChange} checked={this.state.skill === 'Advanced'} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Professional</div>
                            <div className="choiceCheck"><input name="skill" type="radio" value="Professional" onChange={this.onRadioChange} checked={this.state.skill === 'Professional'} /></div>
                            <div className="clearer"></div>
                        </div>
                    </div>
                    <div className="basic-btn" onClick={(e) => this.prevStep(e)}>Back</div>
                    <div className="basic-btn" onClick={(e) => this.nextStep(e)}>Next</div>
                    <div className="footer">{pid}</div>
                </div>
            );
        } else if(this.state.step === 2) {
            let fargoInput = this.getFargoRateInput();
            return(
                <div className="page">
                    <h1>Got a Fargo Rating?</h1>
                    <div className="choices">
                        <div className="choice">
                            <div className="choiceLabel">Nope</div>
                            <div className="choiceCheck"><input name="fargo" value='no' type="radio" onChange={this.onRadioChange} checked={this.state.fargo === 'no'}/></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Yup!</div>
                            <div className="choiceCheck"><input name="fargo" value='yes' type="radio" onChange={this.onRadioChange} checked={this.state.fargo === 'yes'} /></div>
                            <div className="clearer"></div>
                        </div>
                        {fargoInput}
                    </div>
                    <div className="basic-btn" onClick={(e) => this.prevStep(e)}>Back</div>
                    <div className="basic-btn" onClick={(e) => this.nextStep(e)}>Next</div>
                    <div className="footer">{pid}</div>
                </div>
            );
        } else if(this.state.step === 3) {
            return(
                <div className="page">
                    <h1>Friendly or Money Game?</h1>
                    <div className="choices">
                        <div className="choice">
                            <div className="choiceLabel">Friendly match (I'll cover the table fees)</div>
                            <div className="choiceCheck"><input name="stakes" type="radio" value="stakes1" onChange={this.onRadioChange} checked={this.state.stakes === "stakes1"} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Friendly match (split table fees)</div>
                            <div className="choiceCheck"><input name="stakes" type="radio" value="stakes2" onChange={this.onRadioChange} checked={this.state.stakes === "stakes2"} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Loser pays for the table</div>
                            <div className="choiceCheck"><input name="stakes" type="radio" value="stakes3" onChange={this.onRadioChange} checked={this.state.stakes === "stakes3"} /></div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel">Let's make it interesting!</div>
                            <div className="choiceCheck"><input name="stakes" type="radio" value="stakes4" onChange={this.onRadioChange} checked={this.state.stakes === "stakes4"} /></div>
                            <div className="clearer"></div>
                        </div>
                    </div>
                    <div className="basic-btn" onClick={(e) => this.prevStep(e)}>Back</div>
                    <div className="basic-btn" onClick={(e) => this.nextStep(e)}>Next</div>
                    <div className="footer">{pid}</div>
                </div>
            );
        } else if(this.state.step === 4) {
            return(
                <div className="page">
                    <h1>What Table Are You On?</h1>
                    <div className="choices">
                    <div className="choiceText">
                        <input type="text" 
                            placeholder="Table Number" 
                            value={this.state.table} 
                            onChange={e=>{
                                this.setState({table: e.target.value});
                            }} />
                    </div>
                    </div>
                    <div className="basic-btn" onClick={(e) => this.prevStep(e)}>Back</div>
                    <div className="basic-btn" onClick={(e) => this.nextStep(e)}>Next</div>
                    <div className="footer">{pid}</div>
                </div>
            );
        } else if(this.state.step === 5) {
            return(
                <div className="page">
                    <h1>Let's Review</h1>
                    <div className="choices">
                        <div className="choice">
                            <div className="choiceLabel"><strong>Games:</strong> {this.getGames()}</div>
                            <div className="choiceCheck" onClick={()=>this.gotoStep(0)}>EDIT</div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel"><strong>Your Skill Level:</strong> {this.state.skill}</div>
                            <div className="choiceCheck" onClick={()=>this.gotoStep(1)}>EDIT</div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel"><strong>Fargo Rating:</strong> {this.state.fargoRating ? this.state.fargoRating : 'None'}</div>
                            <div className="choiceCheck" onClick={()=>this.gotoStep(2)}>EDIT</div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel"><strong>Stakes:</strong> {this.getStakes()} </div>
                            <div className="choiceCheck" onClick={()=>this.gotoStep(3)}>EDIT</div>
                            <div className="clearer"></div>
                        </div>
                        <div className="choice">
                            <div className="choiceLabel"><strong>Table Number:</strong> {this.state.table}</div>
                            <div className="choiceCheck" onClick={()=>this.gotoStep(4)}>EDIT</div>
                            <div className="clearer"></div>
                        </div>
                    </div>
                    <div className="basic-btn" onClick={(e) => this.prevStep(e)}>Back</div>
                    <div className="basic-btn" onClick={()=>this.saveInvite()}>Broadcast Invite</div>
                    <div className="footer">{pid}</div>
                </div>
            );
        }
        
        
    }
}

Create.contextType = AppContext;
export default Create;