import React, { Component } from "react";
import { Link } from "react-router-dom";

class BasicButton extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render(){
        return(
            <Link to={this.props.to}>
                <div className="basic-btn">{this.props.label}</div>
            </Link>
        );
    }
}

export default BasicButton;