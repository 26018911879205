import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams,
  useRouteMatch
} from "react-router-dom";

//import RequestAccess from "./pages/RequestAccess";
import Auth from './pages/Auth';
import Search from './pages/Search';
import Create from './pages/Create';
import Room from './pages/Room';
import SearchByZip from './pages/SearchByZip';
import { AppContext } from "./providers/AppProvider";
import { auth } from "./firebase.js";

export default function PageRouter() {
    console.log("PageRouter Loaded");

    return (
        <Router>
          <div>
            <Switch>
              <Route path="/create">
                <CreatePage />
              </Route>
              <Route path="/zip">
                <ZipSearch />
              </Route>
              <Route path="/room">
                <RoomPage />
              </Route>
              <Route path="/start">
                <StartPage />
              </Route>
              <Route path="/signout">
                <SignOut />
              </Route>
              <Route path="/">
                <AuthPage />
              </Route>
            </Switch>
          </div>
        </Router>
      );
}

function AuthPage(props){
  const session = useContext(AppContext);
    if(session.user) return <Redirect to='/start' />;

  return(
    <Auth />
  );
}

function StartPage(props){
    console.log("START PAGE");
    let { path, url } = useRouteMatch();
    let { invitecode } = useParams();
    console.log("Path = " + path);
    console.log("URL = " + url);

    const session = useContext(AppContext);
    if(!session.user) return <Redirect to='/' />;

    return (
      <Search />
    );
}

function ZipSearch(props){
  console.log("ZIP SEARCH PAGE");
  let { path, url } = useRouteMatch();
  console.log("Path = " + path);
  console.log("URL = " + url);

  return <SearchByZip />
}

function CreatePage(props){
  console.log("CREATE PAGE");
  let { path, url } = useRouteMatch();
  let { inviteNum } = useParams();
  console.log("Path = " + path);
  console.log("URL = " + url);

  const session = useContext(AppContext);
  if(!session.user) return <Redirect to='/' />;

  return(
    <Create />
  );
}

function RoomPage(props){
  console.log("ROOM PAGE");
  let { path, url } = useRouteMatch();
  console.log("Path = " + path);
  console.log("URL = " + url);

  const session = useContext(AppContext);
  if(!session.user) return <Redirect to='/' />;

  return(
    <Room />
  );
}

function NoCode() {
    console.log("NO CODE PAGE");

    return(
      <div>ERROR: No Place ID!</div>
    );
}

function SignOut(){
  console.log("SIGNING OUT");
  const session = useContext(AppContext);
  auth.signOut();

  return (
    session.user ?
      <div>Still Signed In.</div>
    :
    <Redirect to="/" />
  )
}